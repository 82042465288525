<script setup lang="ts">
import { buttonProps } from 'naive-ui'

const props = defineProps(buttonProps)
</script>

<template>
  <NButton v-bind="props" :round="true">
    <template #icon>
      <Icon name="i-ic-baseline-add" />
    </template>
    Hinzufügen
  </NButton>
</template>
